import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

interface props {
  children: JSX.Element;
}

function ProtectedRoute({ children }: props) {
  const [cookies] = useCookies(['auth']);

  const isAutenticated = cookies.auth !== undefined;

  return isAutenticated ? children : <Navigate to="/" />;
}

export default ProtectedRoute;
