import styled from 'styled-components';

export const DivStatusBar = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  //background: white;
  z-index: 20;
  position: relative;
  flex-direction: row-reverse;
  justify-content: end;
  justify-items: center;
`;

export const DivContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: center;
`;

export const DivContentAt = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: center;
`;

export const DivActions = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-around;
  justify-items: center;

  button {
    width: 150px;
    height: 30px;
    border: 0;
    cursor: pointer;
    background-color: #4254f5;
    border-radius: 10px;
    color: #ffffff;
    font-weight: 500;
    font-size: small;

    transition: background-color 0.2s;
    &:hover {
      background-color: #6c79f5;
    }
  }
`;
