import { IIformacoesVendedor } from '../../interfaces/InformacoesVendedor';
import { DivActions, DivContent, DivContentAt, DivStatusBar } from './style';
import '../../styles/battery.scss';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
} from '@mui/material';
import { Progress } from 'antd';

interface InfoProps {
  info: IIformacoesVendedor;
}

export function InformacoesVendedor({ info }: InfoProps) {
  let statusBattery;
  console.log(info);
  if (info.carga_bateria > 40) {
    statusBattery = (
      <div className="battery">
        <div
          className={'battery-level'}
          style={{ height: info.carga_bateria.toString() + '%' }}
        ></div>
      </div>
    );
  } else if (info.carga_bateria <= 10) {
    statusBattery = (
      <div className="battery">
        <div
          className={'battery-level alert'}
          style={{ height: info.carga_bateria.toString() + '%' }}
        ></div>
      </div>
    );
  } else {
    statusBattery = (
      <div className="battery">
        <div
          className={'battery-level warn'}
          style={{ height: info.carga_bateria.toString() + '%' }}
        ></div>
      </div>
    );
  }
  return (
    <>
      <DivStatusBar>{statusBattery}</DivStatusBar>
      <Card>
        <CardHeader
          title={`${info.vendedor_id} - ${info.user_name}`}
          subheader={`Data Ult. Sinc.: ${info.data_ultimo_sincronismo}`}
        />
        <CardMedia component="hr" />
        <CardContent>
          <DivContent>
            <h5>Qtde de Clientes:</h5>
            <h5>Vendas Pendendes:</h5>
            <h5>Total de Vendas Pend.:</h5>
            <h4>{info.qtde_clientes}</h4>
            <h4>{info.qtde_vendas_pendentes}</h4>
            <h4>
              {info.total_vendas_pendendes > 0
                ? info.total_vendas_pendendes.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : '0.00'}
            </h4>
          </DivContent>
        </CardContent>
        <CardMedia component="hr" />
        <CardContent>
          <Progress percent={info.clientes_positivados} status="active" />
          <h5>{`${info.clientes_positivados} / ${info.qtde_clientes} clientes positivados.`}</h5>
          <Progress percent={info.clientes_visitados} status="active" />
          <h5>{`${info.clientes_visitados} / ${info.qtde_clientes} clientes visitados e não positivados.`}</h5>
        </CardContent>
        <CardMedia component="hr" />
        <CardContent>
          <DivContentAt>
            <h5>Primeiro Cliente Atendido:</h5>
            <h5>Último Cliente Atendido:</h5>
            <h4>{info.primeiro_atendimento}</h4>
            <h4>{info.ultimo_atendimento}</h4>
          </DivContentAt>
        </CardContent>
        <CardMedia component="hr" />
        <CardActions>
          <DivActions>
            <button>Configurações</button>
            <button>Exibir Clientes no Mapa</button>
          </DivActions>
        </CardActions>
      </Card>
    </>
  );
}
