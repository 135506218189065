import '../styles/status.css';

interface props {
  status: boolean;
}

export const StatusConexao = ({ status }: props) => {
  return (
    <div id="center-div">
      {status ? (
        <div className="bubble">
          <span className="bubble-outer-dot">
            <span className="bubble-inner-dot"></span>
          </span>
        </div>
      ) : (
        <div className="bubbleOff"></div>
      )}
    </div>
  );
};
