import { useEffect, useState } from "react";
import { LeafletTrackingMarker } from "react-leaflet-tracking-marker";
import { icon } from 'leaflet';

import airplaneIcon from "../images/seller.svg";

const iconCar = icon({
  iconSize: [40, 50],
  popupAnchor: [2, -20],
  iconUrl: airplaneIcon,
  scrollWheelZoom: 'center' 
});

export default function CarMarker({ data }) {
  const { lat, lng, alt} = data;
  const [prevPos, setPrevPos] = useState([lat, lng]);

  useEffect(() => {
    if (prevPos[1] !== lng && prevPos[0] !== lat) setPrevPos([lat, lng]);
  }, [lat, lng, prevPos]);

  return (
    <LeafletTrackingMarker
      icon={iconCar}
      position={[lat, lng]}
      previousPosition={prevPos}
      duration={1000}
      keepAtCenter={true}
      rotationAngle={alt}
    />
  );
}