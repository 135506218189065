import { useEffect, useState } from 'react';

const useLocation = () => {
  interface geoLocation {
    lat: number;
    lng: number;
  }
  const [location, setLocation] = useState<geoLocation>({
    lat: -8.8693239,
    lng: -36.4499773,
  });

  const onSuccess = (position: GeolocationPosition) => {
    setLocation({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
  };

  const onError = (error: GeolocationPositionError) => {};

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return location;
};

export default useLocation;
