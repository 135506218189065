import { Routes, Route } from 'react-router-dom';
import LocalizarPedidos from '../pages/LocalizarPedidos';
import { LocalizarVeiculo } from '../pages/LocalizarVeiculos';
import { LocalizarVendedor } from '../pages/LocalizarVendedor';
import MainPage from '../pages/NewMain';
import LoginForm from '../pages/Login';
import { AuthProvider } from '../context/AuthContext';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from '../components/PortectedRoute';

export const AppRoutes = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route
          path="home"
          element={
            <ProtectedRoute>
              <MainPage />
            </ProtectedRoute>
          }
        >
          <Route
            path="pedidos"
            element={
              <ProtectedRoute>
                <LocalizarPedidos />
              </ProtectedRoute>
            }
          />
          <Route
            path="vendedor"
            element={
              <ProtectedRoute>
                <LocalizarVendedor />
              </ProtectedRoute>
            }
          />
          <Route
            path="veiculo"
            element={
              <ProtectedRoute>
                <LocalizarVeiculo />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
      <ToastContainer />
    </AuthProvider>
  );
};
