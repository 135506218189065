// AuthContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

export interface User {
  id: number;
  usuario: string;
  senha: string;
  lembrar: string;
  tenant_id: number;
}

interface AuthContextType {
  user: User | null;
  login: (userData: User) => void;
  logout: () => void;
}

interface UserProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: UserProps) => {
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const [, setCookie, removeCookie] = useCookies(['auth']);

  const login = async (userData: User) => {
    const user_login = {
      usuario: userData.usuario.toLowerCase(),
      senha: userData.senha,
    };

    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 10 * 60 * 1000);
    try {
      const response = await api.post<User>('login', user_login);
      console.log(response.data);

      setUser(response.data);
      setCookie('auth', response.data, { expires: expirationDate });
      navigate('home/pedidos');
    } catch (error) {
      setUser(null);
      setCookie('auth', null, { expires: expirationDate });
      toast.error('Usuário ou senha invalido!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'dark',
      });
    }
  };

  const logout = () => {
    setUser(null);
    removeCookie('auth');
    navigate('/');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
