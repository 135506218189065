import React, { FormEvent, useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import '../styles/style.css';
import useLocation from '../hooks/useLocation';
import api from '../services/api';
import iconMap from '../images/icons8-shipping-100.png';
import mapIcon from '../components/mapIcon';
import { IVendedor } from '../interfaces/Vendedor';
import { IPedido } from '../interfaces/Pedido';
import { IEndereco } from '../interfaces/Endereco';
import styled, { css } from 'styled-components';
import { Map } from 'leaflet';
import menuImg from '../images/menu-line.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
} from '@mui/material';

import TablePedidos from '../components/Table';
import { useCookies } from 'react-cookie';
import { Empresa } from '../interfaces/Empresa';
import { Spin } from 'antd';

function LocalizarPedidos() {
  const [vendedores, setVendedores] = useState<IVendedor[]>([]);
  const [empresas, setEmpresas] = useState<Empresa[]>([]);
  const [pedidos, setPedidos] = useState<IPedido[]>([]);
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [selectOption, setSelectOption] = useState('0');
  const [selectEmpresa, setselectEmpresa] = useState('0');
  const [loading, setLoading] = useState(false);
  const [loadingEndereco, setloadingEndereco] = useState(false);
  const [endereco, setEndereco] = useState<IEndereco>({} as IEndereco);
  const location = useLocation();
  const [map, setMap] = useState<Map>();
  const [menuOpen, setMenuOpen] = useState(true);
  const [cookies] = useCookies(['auth']);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.name === 'dataI') {
      setDataInicial(e.currentTarget.value);
    } else if (e.currentTarget.name === 'dataF') {
      setDataFinal(e.currentTarget.value);
    }
  };

  async function loadPedidos() {
    setLoading(true);
    const response = await api.get<IPedido[]>(
      `vendas/${selectEmpresa}/${selectOption}/${dataInicial}/${dataFinal}/`
    );
    setPedidos(response.data);

    setLoading(false);

    if (response.data.length === 0) {
      toast.info('Nenhum registro encontrado!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'dark',
      });
      return;
    }

    FlyToButton(response.data);
    setMenuOpen(false);
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (selectEmpresa === '0') {
      toast.error('Nenhuma empresa selecionada!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'dark',
      });
      return;
    }

    if (selectOption === '0') {
      toast.error('Nenhum vendedor selecionado!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'dark',
      });
      return;
    }

    loadPedidos();
    //setSelectOption('');
  }

  function addZero(value: number) {
    if (value <= 9) {
      return '0' + value.toString();
    } else {
      return value.toString();
    }
  }

  const loadEndereco = async (lat: number, lng: number) => {
    setloadingEndereco(true);
    const response = await api.get<IEndereco>(`getEndereco/${lat}/${lng}`);
    const data = response.data;
    setEndereco(data);
    setloadingEndereco(false);
  };

  async function loadEmpresas() {
    const response = await api.get<Empresa[]>(
      `empresas/${cookies.auth?.tenant_id}`
    );
    setEmpresas(response.data);
  }

  async function loadVendedores(id: string) {
    setLoading(true);
    const response = await api.get<IVendedor[]>(`vendedores/${id}`);
    setVendedores(response.data);
    setLoading(false);
  }

  useEffect(() => {
    //console.log(cookies);
    let dataAtual = new Date();
    const dataFormatada =
      dataAtual.getFullYear().toString() +
      '-' +
      addZero(dataAtual.getMonth() + 1) +
      '-' +
      addZero(dataAtual.getDate());
    setDataInicial(dataFormatada);
    setDataFinal(dataFormatada);

    loadEmpresas();
    //loadVendedores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.auth?.tenant_id]);

  function FlyToButton(data: IPedido[]) {
    map?.flyTo([data[0].lat, data[0].lng], 16);
  }

  function FlyToPedido(data: IPedido) {
    map?.flyTo([data.lat, data.lng], 16);
  }

  return (
    <Container>
      <Form isMenuOpen={menuOpen} onSubmit={handleSubmit}>
        <button type="button" onClick={handleToggleMenu}>
          <img src={menuImg} alt="Abrir e fechar o menu" />
        </button>
        <fieldset>
          <legend>Filtros</legend>
          <InputBlock>
            <label htmlFor="dataInicial">Data Inicial</label>
            <input
              id="dataInicial"
              name="dataI"
              type={'date'}
              value={dataInicial}
              onChange={handleChange}
            />

            <label htmlFor="dataFinal">Data Final</label>
            <input
              id="dataFinal"
              name="dataF"
              type={'date'}
              value={dataFinal}
              onChange={handleChange}
            />

            <label htmlFor="empresa">Empresa</label>
            <select
              defaultValue={''}
              onChange={(e) => {
                loadVendedores(e.target.value);
                setselectEmpresa(e.target.value);
              }}
            >
              <option key={0} value={''} disabled>
                Selecione uma empresa
              </option>
              {empresas.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.nome}
                  </option>
                );
              })}
            </select>
            <label htmlFor="vendedor">Vendedor</label>
            <select
              defaultValue={''}
              onChange={(e) => {
                setSelectOption(e.target.value);
              }}
            >
              <option key={0} value={''} disabled>
                Selecione o vendedor
              </option>

              {loading ? (
                <Spin size="large" />
              ) : (
                vendedores.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.nome}
                    </option>
                  );
                })
              )}
            </select>
            <button className="confirm-button" type="submit">
              Confirmar
            </button>
          </InputBlock>
        </fieldset>
      </Form>
      <MapContainer
        center={[location.lat, location.lng]}
        zoom={18}
        style={{
          width: '100%',
          height: '100%',
        }}
        zoomControl={false}
        whenCreated={(map) => setMap(map)}
      >
        {process.env.REACT_APP_MAP_TYPE === 'OPEN' ? (
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        ) : (
          <ReactLeafletGoogleLayer
            apiKey={process.env.REACT_APP_MAP_API || ''}
            useGoogMapsLoader={true}
            googleMapsAddLayers={[
              { name: 'TransitLayer' },
              { name: 'TrafficLayer' },
            ]}
          />
        )}
        {loading ? (
          /*   {/* <DarkBackground>
            <ReactLoading
              type={'spinningBubbles'}
              color={'#ffff'}
              height={'7%'}
              width={'7%'}
            />
          </DarkBackground> } */
          <Spin size="large" fullscreen={true} delay={5} />
        ) : (
          pedidos.map((pedido) => (
            <Marker
              key={pedido.id}
              position={[pedido.lat, pedido.lng]}
              icon={mapIcon(iconMap)}
              eventHandlers={{
                click: (e) => {
                  loadEndereco(pedido.lat, pedido.lng);
                },
              }}
            >
              <Popup
                maxWidth={380}
                key={pedido.id}
                closeButton={false}
                eventHandlers={{
                  popupclose: (e) => {
                    setEndereco({} as IEndereco);
                  },
                }}
              >
                {loadingEndereco ? (
                  <div className="load">
                    {/* <ReactLoading
                      type={'spinningBubbles'}
                      color={'#290196'}
                      height={'100%'}
                      width={'100%'}
                    /> */}
                    <Spin size="large" />
                  </div>
                ) : (
                  <DivPopup>
                    <Card>
                      <CardHeader
                        title={`Dados do Pedido: ${pedido.id}`}
                        subheader={`Emissão: ${pedido.emissao} ${pedido.hora}`}
                      />
                      <CardMedia component="hr" />
                      <CardContent>
                        <h3>Data e hora do envio:</h3>
                        <h4>
                          {pedido.data_envio} {pedido.hora_envio}
                        </h4>
                        <h3>Razão Social: </h3>
                        <h4>{pedido.cliente}</h4>
                        <h3>Nome Fantasia: </h3>
                        <h4>{pedido.fantasia}</h4>
                      </CardContent>
                    </Card>
                    <p></p>
                    <Card>
                      <CardHeader
                        title={`Localização do Pedido`}
                        subheader={`Lat: ${pedido.lat} Lng: ${pedido.lng}`}
                      />
                      <CardMedia
                        component="iframe"
                        src={`https://maps.google.com/maps/embed/v1/streetview?location=${pedido.lat},${pedido.lng}
                        &key=AIzaSyDSboZhKsuUMZzwYR359HIcJpeS1oQoKDE`}
                      />
                      <CardContent>
                        <h3>Endereço:</h3>
                        <h4>{endereco.streetName}</h4>
                        <h3>Cidade / Estado / Cep:</h3>
                        <h4>
                          {endereco.city +
                            ' - ' +
                            endereco.state +
                            ' - ' +
                            endereco.zipcode}
                        </h4>
                      </CardContent>
                      <CardActionArea>
                        <a
                          href={`http://maps.google.com/maps?q=&layer=c&cbll=${pedido.lat},${pedido.lng}&cbp=11,0,0,0,0`}
                          target="blank"
                        >
                          <b> Google Street View </b>
                        </a>
                      </CardActionArea>
                    </Card>
                    <p></p>
                  </DivPopup>
                )}
              </Popup>
            </Marker>
          ))
        )}
      </MapContainer>
      <TableContainer>
        <TablePedidos
          pedidos={pedidos}
          onFlyToPedido={FlyToPedido}
          isLoading={loading}
        />
      </TableContainer>
      <ToastContainer />
    </Container>
  );
}

interface FormProps {
  isMenuOpen: boolean;
}

const DivPopup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  hr {
    border-color: #6198f0;
    display: block;
    margin-left: 7px;
    margin-right: 7px;
    border-style: inset;
    border-width: 0.5px;
  }

  h4 {
    color: gray;
  }

  a {
    padding-left: 15px;
    padding-bottom: 15px;
  }

  iframe {
    border: 0;
  }
`;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 0px);
  z-index: 0;
  padding: 0px;

  .leaflet-container {
    z-index: 0;
  }
`;

const TableContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0px;
  background-color: white;
  z-index: 0;

  .ant-table-cell {
    font-size: small;
    cursor: pointer;
  }
`;

const Form = styled.form<FormProps>`
  display: flex;
  width: 300px;
  height: 480px;
  flex-direction: column;

  background: #ffffff;
  border: 1px solid #d3e2e5;
  border-radius: 10px;

  padding: 5px 5px;

  position: absolute;
  top: 20px;
  left: 20px;

  z-index: 1;

  ${({ isMenuOpen }) =>
    isMenuOpen
      ? css`
          width: 300px;
          height: 480px;
        `
      : css`
          width: 50px;
          height: 50px;
          transition: width 1s, height 1s;
          border-radius: 10px;
          padding: 5px 5px;
        `}

  transition: width 1s, height 1s;

  button {
    background: none;
    border: none;
    width: 30px;
  }

  fieldset {
    border: 0;
    ${({ isMenuOpen }) =>
      isMenuOpen
        ? css`
            visibility: visible;
            opacity: 1;
          `
        : css`
            visibility: hidden;
            opacity: 0;
          `}
    transition: opacity 1s;
    transition-delay: 0.6s;
    legend {
      width: 100%;

      font-size: 20px;
      line-height: 34px;
      color: #5c8599;
      font-weight: 700;

      border-bottom: 1px solid #d3e2e5;
      margin-bottom: 0px;
      padding-bottom: 10px;
    }
  }
`;

const InputBlock = styled.div`
  margin-top: 5px;
  label {
    display: flex;
    color: #8fa7b3;
    margin-bottom: 0px;
    line-height: 24px;
    font-size: 12px;
  }

  span {
    font-size: 14px;
    color: #8fa7b3;
    margin-left: 20px;
    line-height: 24px;
  }

  input,
  select {
    width: 100%;
    background: #f5f8fa;
    border: 1px solid #d3e2e5;
    border-radius: 10px;
    outline: none;
    color: #5c8599;
    margin-bottom: 10px;
    height: 44px;
    padding: 0 16px;
  }
  button {
    margin-top: 10px;

    width: 100%;
    height: 44px;
    border: 0;
    cursor: pointer;
    background-color: #4254f5;
    border-radius: 20px;
    color: #ffffff;
    font-weight: 800;

    transition: background-color 0.2s;
    &:hover {
      background-color: #6c79f5;
    }
  }
`;

/* const DarkBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: none;
  background-color: rgba(0, 0, 0, 0.9);
  visibility: visible;
`; */

export default LocalizarPedidos;
