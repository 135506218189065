import { icon, Icon, IconOptions } from 'leaflet';

export default function mapIcon(iconUrl: string): Icon<IconOptions> {
  return icon({
    iconUrl: iconUrl,
    iconSize: [58, 58],
    iconAnchor: [29, 68],
    popupAnchor: [0, -60],
  });
}
