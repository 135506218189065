import { Empty, Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { IPedido } from '../../interfaces/Pedido';

const columns: TableColumnsType<IPedido> = [
  {
    title: 'Código:',
    dataIndex: 'id',
    width: 8,
  },
  {
    title: 'Emissão:',
    dataIndex: 'emissao',
    width: 9,
  },
  {
    title: 'Hora:',
    dataIndex: 'hora',
    width: 8,
  },
  {
    title: 'Cliente:',
    dataIndex: 'cliente',
    width: 40,
  },
  {
    title: 'Nome Fantasia:',
    dataIndex: 'fantasia',
    width: 40,
  },
  {
    title: 'Data do Envio:',
    dataIndex: 'data_envio',
    width: 10,
  },
  {
    title: 'Hora do Envio:',
    dataIndex: 'hora_envio',
    width: 10,
  },
];

interface TableProps {
  pedidos: IPedido[];
  onFlyToPedido: (data: IPedido) => void;
  isLoading: boolean;
}

function TablePedidos({ pedidos, onFlyToPedido, isLoading }: TableProps) {
  const newPedidos = pedidos.map((item) => {
    return {
      ...item,
      key: item.id,
    };
  });

  return (
    <Table
      columns={columns}
      dataSource={newPedidos}
      pagination={{ pageSize: 10 }}
      scroll={{ y: 150 }}
      loading={isLoading}
      size="small"
      locale={{
        emptyText: (
          <Empty
            description={'Nenhum pedido encontrado!'}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ),
      }}
      onRow={(record, index) => {
        return {
          onClick: (event) => {
            onFlyToPedido(record);
          },
        };
      }}
    />
  );
}

export default TablePedidos;
