import { Form, Input, Button, Checkbox, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { User, useAuth } from '../../context/AuthContext';
import './style.css';

const LoginForm = () => {
  const { login } = useAuth();

  const onFinish = (values: User) => {
    login(values);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card style={{ width: 400 }}>
        <img
          src={require('../../images/logo_icone.png')}
          className="ideia"
          alt=""
        />
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ lembrar: false }}
          onFinish={onFinish}
          layout="vertical"
          //size="default"
        >
          <Form.Item
            label="Usuário:"
            name="usuario"
            rules={[
              { required: true, message: 'Por favor informe seu usuário!' },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Informe seu Usuário"
            />
          </Form.Item>
          <Form.Item
            label="Senha:"
            name="senha"
            rules={[
              { required: true, message: 'por favor informe sua senha!' },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Informe sua Senha"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="lembrar" valuePropName="checked" noStyle>
              <Checkbox>Lembrar</Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              block
            >
              Entrar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginForm;
