import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { MapContainer } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import {
  getDatabase,
  ref,
  onValue,
  query,
  limitToLast,
} from 'firebase/database';
import CarMarker from '../components/CarMarker';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_MAP_API || '',
  authDomain: 'shop-adddc.firebaseapp.com',
  databaseURL: 'https://shop-adddc-default-rtdb.firebaseio.com',
  projectId: 'shop-adddc',
  storageBucket: 'shop-adddc.appspot.com',
  messagingSenderId: '14321776573',
  appId: '1:14321776573:web:e985bbb3fc9febfd11c4d4',
};

const app = initializeApp(firebaseConfig);
var database = getDatabase(app);

// interface IPosition {
//   id: string | null;
//   lat: number;
//   lng: number;
// }
/* function sleep(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
} */

export const LocalizarVeiculo = () => {
  const [position, setPosition] = useState({ lat: 0, lng: 0, alt: 0 });

  useEffect(() => {
    const deviceRef = ref(database, 'navigation-log/devices');
    const q = query(deviceRef, limitToLast(1));
    onValue(q, (snapshot) => {
      snapshot.forEach((dataSnapShot) => {
        const dados = dataSnapShot.val();
        setPosition({
          lat: dados.geo.latitude,
          lng: dados.geo.longitude,
          alt: dados.geo.bearing,
        });
      });
    });
  }, []);

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      {' '}
      <MapContainer
        center={[position.lat, position.lng]}
        zoom={18}
        style={{ width: '100%', height: '100%' }}
        zoomControl={false}
        //trackResize={true}
        zoomAnimation={true}
        markerZoomAnimation={true}
        zoomAnimationThreshold={100}
        scrollWheelZoom={'center'}
      >
        <ReactLeafletGoogleLayer
          apiKey="AIzaSyDzPNnLCsSBgIS31ebJMb5mENfWVNcEWuE"
          useGoogMapsLoader={false}
          googleMapsAddLayers={[
            { name: 'TransitLayer' },
            { name: 'TrafficLayer' },
          ]}
        />
        {/* <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        /> */}
        {
          <CarMarker data={position ?? { lat: 0, lng: 0, alt: 0 }} />
          // <ReactLeafletDriftMarker
          //   // if position changes, marker will drift its way to new position
          //   position={position}
          //   // time in ms that marker will take to reach its destination
          //   duration={1000}
          //   icon={mapIcon(iconMap)}
          //   keepAtCenter={true}
          // >
          //   {/* <Popup>Hi this is a popup</Popup>
          //   <Tooltip>Hi here is a tooltip</Tooltip> */}
          // </ReactLeafletDriftMarker>
          // <Marker
          //   key={position.id}
          //   position={[position.lat, position.lng]}
          //   icon={mapIcon(iconMap)}
          //   eventHandlers={{
          //     click: (e) => {
          //       //loadEndereco(pedido.lat, pedido.lng);
          //     },
          //   }}
          // >
          //   {/* <Popup
          //       key={pedido.id}
          //       closeButton={false}
          //       eventHandlers={{
          //         popupclose: (e) => {
          //           //setEndereco({} as IEndereco);
          //         },
          //       }}
          //     >
          //       {loadingEndereco ? (
          //         <div className="load">
          //           <ReactLoading
          //             type={'spinningBubbles'}
          //             color={'#290196'}
          //             height={'100%'}
          //             width={'100%'}
          //           />
          //         </div>
          //       ) : (
          //         <div>
          //           <h5>Pedido: {pedido.id}</h5>
          //           <h5>Cliente: {pedido.cliente}</h5>
          //           <p></p>
          //           <h5>{endereco.streetName}</h5>
          //           <h5>
          //             {endereco.city +
          //               ' - ' +
          //               endereco.state +
          //               ' - ' +
          //               endereco.zipcode}
          //           </h5>
          //         </div>
          //       )}
          //     </Popup> */}
          // </Marker>
        }
      </MapContainer>
    </div>
  );
};
