import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import socketIO, { Socket } from 'socket.io-client';
import { StatusConexao } from '../components/statusConecxao';
import '../styles/style.css';
import { IVendedorLocal } from '../interfaces/VendedorLocal';
import styled, { css } from 'styled-components';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { ReactComponent as Satellite } from '../images/satellite.svg';
import '../styles/battery.scss';
import mapIcon from '../components/mapIcon';
import iconMap from '../images/vendedor_online.svg';
import useLocation from '../hooks/useLocation';
import { Empty } from 'antd';
import { Popup } from 'react-leaflet';
import { IIformacoesVendedor } from '../interfaces/InformacoesVendedor';
import { InformacoesVendedor } from '../components/informacoesVendedor';

interface IStatusGps {
  status: boolean;
  user_id: string;
}

interface IPosition {
  lat: number;
  lng: number;
  alt: number;
  user_id: string;
  user_name: string;
}

export const LocalizarVendedor = () => {
  const [vendedorLocal, setVendedorLocal] = useState<IVendedorLocal[]>([]);
  const socket = useRef<Socket>();
  const [gps, setGps] = useState<IStatusGps>({
    status: false,
    user_id: '',
  });
  const location = useLocation();
  const [positions, setPosistions] = useState<IPosition[]>([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let locations: IPosition[] = [];

  const handleUser = (user: IVendedorLocal) => {
    //console.log(user);
    socket.current?.emit('user_connect', {
      id: '1',
      user_id: user.user_id,
      user_name: user.user_name,
      status: user.status,
      socket_id: user.socket_id,
    });
    socket.current?.emit('get_info', user);
  };
  const [informacoesVendedor, setInformacoesVendedor] =
    useState<IIformacoesVendedor>({
      carga_bateria: 80,
      clientes_positivados: 5,
      data_ultimo_sincronismo: '02/04/2023',
      qtde_clientes: 100,
      qtde_vendas_pendentes: 30,
      total_vendas_pendendes: 1580.5,
      clientes_visitados: 15,
      primeiro_atendimento: '14:00:13',
      ultimo_atendimento: '15:00:34',
      user_id: 'UIUYUˆ&*ˆ&ˆ*&UU',
      user_name: 'EDVANIO',
      vendedor_id: 1,
    });

  useEffect(() => {
    socket.current = socketIO(process.env.REACT_APP_BASE_URL || '', {
      withCredentials: false,
    });

    socket.current.on('connect', () => {
      if (socket.current?.connected) {
        console.log(socket.current.connected);
        socket.current.emit('get_users', {});
      }
    });

    socket.current.on('info_device', (data: IIformacoesVendedor) => {
      console.log(data);
      setInformacoesVendedor(data);
    });

    return () => {
      socket.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.current?.on('users', (data) => {
      setVendedorLocal(data);
    });
    return () => {
      socket.current?.off('users');
    };
  }, [vendedorLocal, gps]);

  useEffect(() => {
    socket.current?.on('gps-on-off', (data: IStatusGps) => {
      setGps(data);
      //console.log('gps', gps);
    });
    return () => {
      socket.current?.off('gps-on-off');
    };
  }, [gps]);

  useEffect(() => {
    socket.current?.on('location_info', (data: IPosition) => {
      const position = locations.find((item) => item.user_id === data.user_id);

      if (!position) {
        locations.push(data);
      } else {
        locations[locations.indexOf(position)].lng = position.lng;
        locations[locations.indexOf(position)].lat = position.alt;
        locations[locations.indexOf(position)].alt = position.alt;
      }

      setPosistions(locations);
      //console.log(positions);
    });
    return () => {
      socket.current?.off('location_info');
    };
  }, [positions, locations]);

  return (
    <Container>
      <Form>
        {vendedorLocal.length > 0 ? (
          <List
            sx={{
              width: '100%',
              height: '100%',
              maxWidth: 360,
              bgcolor: 'background.paper',
            }}
          >
            {vendedorLocal.map((item, index) => {
              return (
                <>
                  <ListItem key={index} alignItems="flex-start">
                    <DivItem>
                      <ListItemButton
                        onClick={() => {
                          handleUser(item);
                        }}
                      >
                        <ListItemIcon sx={{ color: 'inherit' }}>
                          <StatusConexao
                            status={item.status === 'on' ? true : false}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              <strong>{item.user_name}</strong>
                            </Typography>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {item.user_id}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItemButton>
                      <DivIcons
                        isOn={item.user_id === gps.user_id && gps.status}
                      >
                        <Satellite />
                      </DivIcons>
                    </DivItem>
                  </ListItem>
                  {/* <Divider variant="inset" component="li"></Divider> */}
                </>
              );
            })}
          </List>
        ) : (
          <Empty description="Nenhum vendedor conectado!"></Empty>
        )}
      </Form>
      <MapContainer
        center={[location.lat, location.lng]}
        zoom={18}
        style={{ width: '100%', height: '100%' }}
        zoomControl={false}
        //trackResize={true}
        //zoomAnimation={true}
        //markerZoomAnimation={true}
        //zoomAnimationThreshold={100}
        //scrollWheelZoom={'center'}
      >
        {process.env.REACT_APP_MAP_TYPE === 'OPEN' ? (
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        ) : (
          <ReactLeafletGoogleLayer
            apiKey={process.env.REACT_APP_MAP_API || ''}
            useGoogMapsLoader={true}
            googleMapsAddLayers={[
              { name: 'TransitLayer' },
              { name: 'TrafficLayer' },
            ]}
          />
        )}
        {positions.map((item, index) => {
          return (
            // <CarMarker key={index} data={item} />
            <Marker
              key={index}
              position={[item.lat, item.lng]}
              icon={mapIcon(iconMap)}
              eventHandlers={{
                click: (e) => {
                  //console.log('jshdjfsjkfhjdjfshdh');
                },
              }}
            >
              <Tooltip direction="top" offset={[0, -60]} permanent={true}>
                {item.user_name}
              </Tooltip>
              <Popup
                maxWidth={380}
                key={item.lat}
                closeButton={false}
                eventHandlers={{
                  popupclose: (e) => {
                    //console.log('close');
                  },
                }}
              >
                <DivPopup>
                  <InformacoesVendedor info={informacoesVendedor} />
                </DivPopup>
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 0px);
  z-index: 0;
  position: relative;
  padding: 0px;

  .leaflet-container {
    z-index: 0;
  }
`;

const Form = styled.form`
  display: flex;
  width: 300px;
  height: 95%;
  flex-direction: column;
  justify-content: center;

  background: #ffffff;
  border: 1px solid #d3e2e5;
  border-radius: 10px;

  padding: 5px 0px;

  position: absolute;
  top: 20px;
  left: 20px;

  z-index: 1;
`;

const DivItem = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  background: #ffffff;
  border: 2px solid #d3e2e5;
  border-radius: 5px;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  flex-direction: row-reverse;
`;

interface IconProps {
  isOn: boolean;
}

const DivIcons = styled.div<IconProps>`
  display: flex;
  width: 40%;
  height: 20px;
  /* background: yellow; */
  z-index: 20;
  position: absolute;
  flex-direction: row-reverse;
  justify-content: end;
  justify-items: center;
  gap: 5px;
  margin: 5px;

  svg {
    ${({ isOn }) =>
      isOn
        ? css`
            fill: #000000;
          `
        : css`
            fill: #cbc6c6;
          `}

    width: 30px;
  }
`;

const DivPopup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  hr {
    border-color: #6198f0;
    display: block;
    margin-left: 7px;
    margin-right: 7px;
    border-style: inset;
    border-width: 0.5px;
  }

  h4 {
    color: gray;
  }

  a {
    padding-left: 15px;
    padding-bottom: 15px;
  }
`;
