import React, { useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  EnvironmentOutlined,
  SolutionOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import { Link, Outlet } from 'react-router-dom';
import './style.css';
import { useAuth } from '../../context/AuthContext';

const { Header, Sider, Content } = Layout;

const NewMain = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { logout } = useAuth();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        width={220}
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          //console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type);
        }}
      >
        <div className="logo" />
        <Menu
          theme="dark"
          mode="vertical"
          //   style={{ height: '92vh' }}
          defaultSelectedKeys={['1']}
        >
          <Menu.ItemGroup title="Geolocalização">
            <Menu.Item key="1">
              <EnvironmentOutlined />
              <span>Rastrear Pedidos</span>
              <Link to="pedidos" />
            </Menu.Item>
            <Menu.Item key="2">
              <SolutionOutlined />
              <span>Monitorar Vendedores</span>
              <Link to="vendedor" />
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Rotas">
            <Menu.Item key="3">
              <UploadOutlined />
              <span>Criar Rotas</span>
              <Link to="pedidos" />
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div className="container">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
              }
            )}

            <LogoutOutlined className="logout" onClick={logout}>
              Sair
            </LogoutOutlined>
          </div>
        </Header>
        <Content
          style={{
            margin: '16px 16px',
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default NewMain;
